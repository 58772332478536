import React from "react"
import styled from "styled-components"

// Styles
import textStyles from "../../styles/textStyles"
import colors from "../../styles/colors"
import mq from "../../styles/breakpoints"

interface IProps {
  html: string
  className?: string
}

const Richtext = ({ html, className }: IProps) => (
  <Container dangerouslySetInnerHTML={{ __html: html }} className={className} />
)

const Container = styled.div<any>`
  h2 {
    ${textStyles.title};
  }

  h3 {
    ${textStyles.subtitle};
  }

  h2,
  h3 {
    margin-top: 32px;

    ${mq.from.M`
      margin-top: 40px;
    `}
  }

  a {
    color: ${colors.caribbean};
  }
`

export default Richtext
