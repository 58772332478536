import React from "react"
import styled from "styled-components"

// Styles
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"

// Components
import SEO from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"
import BackButton from "../components/atoms/BackButton"
import Slice from "../components/slices/Slice"
import Button from "../components/atoms/Button"

// Types
import { IProjectpage } from "../types/pages/project"

interface IProps {
  pageContext: IProjectpage
}

const ProjectPage = ({ pageContext }: IProps) => {
  const metaData = pageContext.metaData
  const project = pageContext.project
  const slices = pageContext.slices

  return (
    <>
      <SEO title={metaData.title} description={metaData.description} />
      <BackButton />
      <StyledContentWrapper size="small">
        {project.date && <Date>{project.date}</Date>}
        {project.tags && (
          <Tags>
            {project.tags.map((tag, i) => (
              <Tag key={`tag-${i}`}>{tag}</Tag>
            ))}
          </Tags>
        )}
        <Title>{project.title}</Title>
        <Intro dangerouslySetInnerHTML={{ __html: project.intro }} />
      </StyledContentWrapper>
      {slices.map((slice, i) => (
        <Slice key={`slice-${i}`} slice={slice} />
      ))}
      <Footer size="small">
        <Button type="internalLink" to="/" label="👈 Back" />
      </Footer>
    </>
  )
}

const StyledContentWrapper = styled(ContentWrapper)`
  padding-top: 96px;
  margin-bottom: 48px;

  ${mq.from.M`
    padding-top: 120px;
    margin-bottom: 56px;
  `}
`

const Date = styled.span`
  display: block;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1;
  font-style: italic;
  opacity: 0.4;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`

const Tag = styled.span`
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px;
  color: ${colors.zuccini};
  background-color: ${colors.caribbean};
  font-size: 16px;
  line-height: 1;
`

const Title = styled.h1<any>`
  ${textStyles.pageTitle};
  margin-bottom: 24px;

  ${mq.from.M`
    margin-bottom: 32px;
  `}
`

const Intro = styled.div`
  > p {
    ${textStyles.intro};
    opacity: 0.4;
  }
`

const Footer = styled(ContentWrapper)`
  padding-bottom: 96px;

  ${mq.from.M`
    padding-bottom: 120px;
  `}
`

export default ProjectPage
