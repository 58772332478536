import React from "react"
import styled from "styled-components"

// Styles
import mq from "../../styles/breakpoints"

// Components
import Button from "./Button"

interface IProps {
  inverted?: boolean
}

const BackButton = ({ inverted = false }: IProps) => (
  <StyledButton type="internalLink" to="/" label="👈" inverted={inverted} />
)

const StyledButton = styled(Button)`
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 18px;

  ${mq.from.M`
    font-size: 24px;
  `}
`

export default BackButton
