import React from "react"
import styled from "styled-components"

// Styles
import textStyles from "../../styles/textStyles"
import colors from "../../styles/colors"
import mq from "../../styles/breakpoints"

// Components
import ContentWrapper from "../ContentWrapper"

// Types
import { IQuoteSlice } from "../../types/components/slices"

const QuoteSlice = ({ data }: IQuoteSlice) => (
  <ContentWrapper size="small">
    <Container>
      <Quote>{data.quote}</Quote>
      {data.author && <Author>- {data.author}</Author>}
    </Container>
  </ContentWrapper>
)

const Container = styled.blockquote`
  width: 80%;
  margin: 0 auto;
  padding: 40px 24px;
  border-left: 2px solid ${colors.rainforrest};
`

const Quote = styled.h4<any>`
  ${textStyles.subtitle};
  margin-bottom: 0;
  color: ${colors.rainforrest};

  ${mq.from.M`
    margin-bottom: 0;
  `}
`

const Author = styled.footer`
  margin-top: 12px;
  color: ${colors.rainforrest};
`

export default QuoteSlice
