import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"

// Styles
import mq from "../../styles/breakpoints"

// Components
import ContentWrapper from "../ContentWrapper"

// Types
import { IImageSlice } from "../../types/components/slices"

const ImageSlice = ({ data }: IImageSlice) => (
  <ContentWrapper>
    <Container halfWidth={data.size === "half-width"}>
      <Image fluid={data.image.url} alt={data.image.alt} />
      {data.image.alt && <Description>{data.image.alt}</Description>}
    </Container>
  </ContentWrapper>
)

const Container = styled.div`
  ${({ halfWidth }: { halfWidth: boolean }) =>
    halfWidth &&
    `
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  `};

  ${mq.from.M`
    ${({ halfWidth }: { halfWidth: boolean }) => halfWidth && `width: 50%;`};
  `}
`

const Description = styled.span`
  display: inline-block;
  margin-top: 8px;
  opacity: 0.4;
  font-size: 12px;
  font-style: italic;

  ${mq.from.M`
    font-size: 14px;
  `}
`

export default ImageSlice
