import React from "react"
import styled from "styled-components"

// Components
import ContentWrapper from "../ContentWrapper"

// Types
import { IIframeSlice } from "../../types/components/slices"

const RichtextSlice = ({ data }: IIframeSlice) => (
  <Container>
    <StyledContentWrapper>
      <div dangerouslySetInnerHTML={{ __html: data.code }} />
    </StyledContentWrapper>
  </Container>
)

const Container = styled.div`
  margin: 0 24px;
`

const StyledContentWrapper = styled(ContentWrapper)`
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);

  iframe {
    width: 100%;
    height: 650px;
    margin-bottom: 0;
  }
`

export default RichtextSlice
