import React from "react"

// Components
import ContentWrapper from "../ContentWrapper"
import Richtext from "../atoms/Richtext"

// Types
import { IRichtextSlice } from "../../types/components/slices"

const RichtextSlice = ({ data }: IRichtextSlice) => (
  <ContentWrapper size="small">
    <Richtext html={data.html} />
  </ContentWrapper>
)

export default RichtextSlice
