import React from "react"
import styled from "styled-components"

// Components
import ImageSlice from "./ImageSlice"
import RichtextSlice from "./RichtextSlice"
import QuoteSlice from "./QuoteSlice"
import IframeSlice from "./IframeSlice"

// Types
import { ISlice } from "../../types/components/slices"

interface IProps {
  slice: ISlice
}

const Slice = ({ slice }: IProps) => {
  let SliceComponent: any
  switch (slice.slice_type) {
    case "image":
      SliceComponent = ImageSlice
      break
    case "richtext":
      SliceComponent = RichtextSlice
      break
    case "quote":
      SliceComponent = QuoteSlice
      break
    case "iframe":
      SliceComponent = IframeSlice
      break
    default:
      return null
  }

  return (
    <Container>
      <SliceComponent {...slice} />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 80px;
`

export default Slice
